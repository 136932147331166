<template>
  <BCard>
    <b-row class="align-items-center mb-2">
      <b-button
        variant="primary"
        class="btn-icon mr-1 ml-2"
        size="sm"
        @click="$router.go(-1)"
      >
        <feather-icon
          icon="ChevronLeftIcon"
          size="20"
        />
      </b-button>
      <h3 class="text-black">
        <strong>
          Respon Massal
        </strong>
      </h3>
    </b-row>

    <b-row class="d-flex justify-content-end gap-3 mb-3 mr-1">
      <b-button
        v-b-modal="'modal-download-ticket'"
        variant="outline-primary"
        class="d-flex rounded-lg"
      >
        <span class="align-self-center mr-1 font-bold">Download Tiket</span>
        <img
          class="w-7"
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/arrow-down-2.svg"
          alt=""
        >
      </b-button>
      <b-button
        v-b-modal="'modal-upload-ticket'"
        v-b-tooltip.hover
        :title="uploadDisabled ? 'Tunggu 10 menit dari upload terakhir' : ''"
        :variant="uploadDisabled ? 'outline-secondary' : 'outline-primary'"
        class="d-flex rounded-lg"
        :disabled="uploadDisabled"
      >
        <span class="align-self-center mr-1 font-bold">Upload Tiket</span>
        <img
          class="w-7"
          :src="uploadDisabled ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/danger.svg' : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/arrow-up-3.svg'"
          alt=""
        >
      </b-button>
    </b-row>

    <b-overlay
      :show="loadingDataTable"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="itemsTicket"
        :fields="fieldsTicket"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
      >
        <template #thead-top="">
          <b-tr>
            <b-th
              colspan="1"
              class="min-w-[13rem]"
            >
              Tanggal Upload
            </b-th>
            <b-th
              colspan="1"
              class="min-w-[12rem]"
            >
              Nama Mitra
            </b-th>
            <b-th
              colspan="3"
              class="min-w-[25rem]"
            >
              Hasil Analisis Tiket
            </b-th>
            <b-th
              colspan="3"
            >
              Update Status Tiket
            </b-th>
            <b-th
              colspan="1"
              class="min-w-[12rem]"
            >
              Action
            </b-th>
          </b-tr>
        </template>
        <template
          #cell(uploaded_date)="data"
        >
          <div>
            {{ data.item.uploaded_date }}
          </div>
        </template>
        <template #cell(ticket_analysis_results.not_appropriate)="data">
          <div class="d-flex justify-between gap-2">
            <span class="align-self-center">{{ data.item.ticket_analysis_results.not_appropriate }}</span>
            <div
              class="border-primary rounded-lg p-[4px]"
              @click="downloadFail(data.item.id)"
            >
              <img
                class="w-5"
                type="button"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-download.svg"
                alt="download"
              >
            </div>
          </div>
        </template>
        <template
          #cell(action)="data"
        >
          <span
            v-if="data.item.status === 'finish'"
            class="border-2 p-[5px] rounded-lg text-[#34A770] bg-[#DCF3EB] border-[#34A770]"
          >
            Selesai
          </span>
          <span
            v-else-if="data.item.status === 'apply'"
          >
            <span
              v-if="onProgress === data.item.id"
              class="border-2 p-[5px] rounded-lg text-[#FBA63C] bg-[#FCEBBE] border-[#FBA63C]"
            >
              On Progress
            </span>
            <span
              v-else
              class="border-2 p-[5px] rounded-lg text-[#F95031] bg-[#FFFFFF] border-[#F95031]"
              type="button"
              @click="applied(data.item.id)"
            >
              Terapkan
            </span>
          </span>
          <span
            v-else-if="data.item.status === 'on_progress'"
            class="border-2 p-[5px] rounded-lg text-[#FBA63C] bg-[#FCEBBE] border-[#FBA63C]"
          >
            On Progress
          </span>
        </template>
      </BTable>
    </b-overlay>
    <modal-download-ticket />
    <modal-upload-ticket
      @get-fetch="fetchData"
      @download-fail="downloadFail"
    />
  </BCard>
</template>
<script>
import { BCard, BButton, BTable } from 'bootstrap-vue'
import { DDMMYYYY, TIME } from '@/libs/filterDate'
import moment from 'moment'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import ModalDownloadTicket from '../ModalDownloadTicket.vue'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    ModalDownloadTicket,
    ModalUploadTicket: () => import('./ModalUploadTicket.vue'),
  },
  props: {
  },
  data() {
    return {
      DDMMYYYY,
      TIME,
      fieldsTicket: [
        {
          key: 'uploaded_date',
          label: '',
        },
        {
          key: 'mitra_name',
          label: '',
        },
        {
          key: 'ticket_analysis_results.total',
          label: 'Total',
        },
        {
          key: 'ticket_analysis_results.appropriate',
          label: 'Sesuai',
        },
        {
          key: 'ticket_analysis_results.not_appropriate',
          label: 'Belum Sesuai',
        },
        {
          key: 'update_status_ticket.succeed',
          label: 'Berhasil',
        },
        {
          key: 'update_status_ticket.fail_implement',
          label: 'Gagal Menerapkan',
        },
        {
          key: 'update_status_ticket.fail_adjust',
          label: 'Gagal Menyesuaikan',
        },
        {
          key: 'action',
          label: '',
        },
      ],
      itemsTicket: [],
      loadingDataTable: false,
      onProgress: null,
      uploadDisabled: false,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loadingDataTable = true
      const url = 'komship/api/v1/ticket/mitra/uploader-data'
      await this.$http_new.get(url)
        .then(res => {
          const { data } = res.data
          if (data.length !== 0) {
            this.itemsTicket = data
            const createdAt = new Date(data[0].created_at)
            const tenMinutesAgo = new Date(createdAt.getTime() + 10 * 60 * 1000)

            if (tenMinutesAgo > new Date()) {
              this.uploadDisabled = true
            }
            this.itemsTicket.forEach((value, index) => {
              if (value.status_queue === 1) {
                this.itemsTicket[index].status = 'on_progress'
              } else if (value.status_queue === 0) {
                this.itemsTicket[index].status = 'finish'
              } else if (value.status_queue === -1) {
                this.itemsTicket[index].status = 'apply'
              }
            })
          }
          this.loadingDataTable = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal mengakses data tabel',
              variant: 'danger',
            },
          }, 2000)
          this.loadingDataTable = false
        })
    },
    applied(id) {
      this.onProgress = id
      const url = 'komship/api/v1/ticket/mitra/bulk-retry-implement'
      const body = {
        id,
      }
      this.$http_new.post(url, body)
        .then(() => {
          this.onProgress = null
          this.fetchData()
        })
        .catch(() => {
          this.onProgress = null
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal menerapkan data tiket',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    downloadFail(value) {
      let id
      if (typeof value !== 'string') {
        id = value.id
      } else {
        id = value
      }
      const url = `komship/api/v1/ticket/mitra/export-excel-fail/${id}`
      this.$http_new.get(url)
        .then(res => {
          const { data } = res.data
          const a = document.createElement('a')
          a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
          a.download = `Tiket_Bulk_Komship_${moment().format('DD-MM-YYYY_HH-mm')}_Belum-Sesuai.xlsx`
          a.click()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal download data tiket',
              variant: 'danger',
            },
          }, 2000)
        })
    },
  },
}
</script>
