<template>
  <BModal
    id="modal-download-ticket"
    ref="modal-download-ticket"
    hide-footer
    hide-header
    modal-class="modal-dark"
    centered
    no-close-on-backdrop
    no-close-on-esc
    size="xl"
  >
    <div class="text-black text-xl font-semibold text-center mt-2">
      Download Data Tiket
    </div>
    <p class="text-center text-black mt-2">
      Data tiket siap diambil.
      <span v-if="getBulkResponse()">Yuk pilih data tiket yang ingin kamu respon massal</span>
      <span v-else>Yuk gunakan sebaik mungkin untuk memaksimalkan koordinasi dengan tim lapangan kamu agar pengiriman cepat tertangani.</span>
    </p>
    <template>
      <BContainer class="text-center col text-black h-72 overflow-auto mb-2">
        <BRow class="mt-2">
          <BCol
            sm="12"
            md="3"
          >
            <BRow class="border-b-1 bg-[#F4F4F4] p-1 mt-2 mt-md-0 font-semibold">
              Waktu Diupdate
            </BRow>
            <BRow class="my-1">
              <DateRangePicker
                ref="picker"
                v-model="rangeDate"
                :opens="'rtl'"
                :locale-data="locale"
                :ranges="ranges"
              >
                <template
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="mr-1">
                      <span
                        v-if="
                          DAY_MONTH_YEAR(picker.startDate) === DAY_MONTH_YEAR(today)
                        "
                        style="color: #828282 !important"
                      >
                        Hari ini
                      </span>
                      <span
                        v-else-if="
                          DAY_MONTH_YEAR(picker.startDate) === DAY_MONTH_YEAR(last7)
                        "
                        style="color: #828282 !important"
                      >
                        7 Hari Terakhir
                      </span>
                      <span
                        v-else-if="
                          DAY_MONTH_YEAR(picker.startDate) === DAY_MONTH_YEAR(last30)
                        "
                        style="color: #828282 !important"
                      >
                        30 Hari Terakhir
                      </span>
                      <span
                        v-else-if="
                          DAY_MONTH_YEAR(picker.startDate) ===
                            DAY_MONTH_YEAR(firstDateOfMonth) &&
                            DAY_MONTH_YEAR(picker.endDate) === DAY_MONTH_YEAR(today)
                        "
                        style="color: #828282 !important"
                      >
                        Bulan ini
                      </span>
                      <span
                        v-else
                        style="color: #828282 !important"
                      >
                        Custom
                      </span>
                    </div>
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/date-picker-icon.svg"
                      alt="Komerce"
                    >
                  </div>
                </template>
              </DateRangePicker>
            </BRow>
          </BCol>
          <BCol
            sm="12"
            md="3"
          >
            <BRow class="border-b-1 bg-[#F4F4F4] p-1  mt-2 mt-md-0 font-semibold">
              Prioritas
            </BRow>
            <BRow
              v-for="item in prioritasList"
              :key="item.value"
              class="my-1"
            >
              <BFormCheckbox
                v-model="item.onCheck"
                class="text-left"
                @change="handleCheckedPrioritas(item)"
              >
                <span class="text-black">{{ item.label }}</span>
              </BFormCheckbox>
            </BRow>
          </BCol>
          <BCol
            sm="12"
            md="3"
          >
            <BRow class="border-b-1 bg-[#F4F4F4] p-1  mt-2 mt-md-0 font-semibold">
              Status Tiket
            </BRow>
            <BRow
              v-for="status in dataStatus"
              :key="status.value"
              class="my-1"
            >
              <BFormCheckbox
                v-model="status.onCheck"
                class="text-left"
                :disabled="isBulkResponse(status.value)"
                @change="handleCheckedStatus(status)"
              >
                <span class="text-black">{{ status.label }}</span>
              </BFormCheckbox>
            </BRow>
          </BCol>
          <BCol
            sm="12"
            md="3"
          >
            <BRow class="border-b-1 bg-[#F4F4F4] p-1  mt-2 mt-md-0 font-semibold">
              Jenis Tiket
            </BRow>
            <BRow
              v-for="item in ticket_type_list"
              :key="item.id"
              class="my-1"
            >
              <BFormCheckbox
                v-model="item.onCheck"
                class="text-left"
              >
                <span class="text-black">{{ item.name }}</span>
              </BFormCheckbox>
            </BRow>
          </BCol>
        </BRow>
      </BContainer>
      <hr>
      <div class="flex items-center justify-between">
        <BCol>
          <div
            v-if="showProgress"
            class="d-flex flex-column"
          >
            <div class="text-[#34A853]">
              Download file
            </div>
            <div class="row w-100 align-items-center">
              <div class="col">
                <b-progress
                  v-if="Math.floor(progressValue) <= 0 "
                  :value="100"
                  :max="100"
                  variant="success"
                  :striped="Math.floor(progressValue) <= 0 ? true : false"
                  :animated="Math.floor(progressValue) <= 0 ? true : false"
                />
                <b-progress
                  v-else
                  :value="progressValue"
                  :max="100"
                  variant="success"
                />
              </div>
              <div>
                {{ Math.floor(progressValue) > 100 ? 100 :0 }}%
              </div>
            </div>
          </div>
          <div
            v-else
            class="text-[#34A853]"
          >
            {{ message }}
          </div>
        </BCol>
        <BCol>
          <div class="flex justify-end">
            <BButton
              variant="outline-primary"
              class="mr-1"
              @click="closeModal"
            >
              Batal
            </BButton>
            <BButton
              variant="primary"
              :disabled="showProgress"
              @click="onFinish"
            >
              <BSpinner
                v-if="showProgress"
                small
              />
              Download
            </BButton>
          </div>
        </BCol>
      </div>
    </template>
  </BModal>
</template>

<script>
import {
  BModal, BFormCheckbox, BContainer, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import {
  firstDateOfMonth, last30, last60, last7, today,
} from '@/store/helpers'
import {
  DAY_MONTH_YEAR, LABELDAY, LABELMONTH, YEAR_MONTH_DAY, FULL_DATE_TIME,
} from '@/libs/filterDate'
import moment from 'moment'
import { mapFields } from 'vuex-map-fields'
import { fieldsModal, statusTicket } from './config'

export default {
  components: {
    BModal, BFormCheckbox, BContainer, BRow, BCol, BButton, DateRangePicker, BSpinner,
  },
  data() {
    return {
      checked: true,
      items: this.ticketType,
      showProgress: false,
      progressValue: 0,
      message: '',
      fields: fieldsModal,
      dataStatus: statusTicket,
      ticketStatus: [],
      ticketPrioritas: [],
      ticketType: [],
      rangeDate: {
        startDate: today,
        endDate: today,
      },
      locale: {
        applyLabel: 'Pilih',
        cancelLabel: 'Batal',
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Hari Ini': [today, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
        Custom: [last60, today],
      },
      firstDateOfMonth,
      last30,
      last7,
      today,
      DAY_MONTH_YEAR,
      ticketTypeItems: [],
      FULL_DATE_TIME,
      prioritasList: [
        { onCheck: true, value: 0, label: 'User Prioritas' },
        { onCheck: true, value: 1, label: 'Urgent' },
        { onCheck: true, value: 2, label: 'Reguler' },
      ],
    }
  },
  computed: {
    ...mapFields('deliverConstraints', ['ticket_type_list']),
  },
  async mounted() {
    await this.defaultFilter()
  },
  methods: {
    defaultFilter() {
      // eslint-disable-next-line no-plusplus
      for (let x = 0; x < this.dataStatus.length; x++) {
        this.dataStatus[x].onCheck = false
      }
      // eslint-disable-next-line no-plusplus
      for (let x = 0; x < this.prioritasList.length; x++) {
        this.prioritasList[x].onCheck = false
      }
      // eslint-disable-next-line no-plusplus
      for (let x = 0; x < this.ticket_type_list.length; x++) {
        this.ticket_type_list[x].onCheck = true
      }
      this.dataStatus.forEach((item, index) => {
        if (item.value === 1 || item.value === 2) {
          this.dataStatus[index].onCheck = true
          this.ticketStatus.push(item.value)
        }
      })
      this.prioritasList.forEach((item, index) => {
        this.prioritasList[index].onCheck = true
        if (item.onCheck) {
          this.ticketPrioritas.push(item.value)
        }
      })
    },
    async onFinish() {
      this.showProgress = true
      this.ticketTypeItems = []
      this.ticket_type_list.forEach((item, index) => {
        if (item.onCheck) {
          this.ticketTypeItems.push(item.id)
        }
      })
      const params = {
        start_date: YEAR_MONTH_DAY(this.rangeDate.startDate),
        end_date: YEAR_MONTH_DAY(this.rangeDate.endDate),
        priority: this.ticketPrioritas.toString(),
        ticket_status: this.ticketStatus.toString(),
        type_ticket: this.ticketTypeItems.toString(),
      }
      const url = '/komship/api/v1/ticket/mitra/export-excel'
      await this.$http_new.get(url, {
        params,
        onDownloadProgress: progressEvent => {
          // eslint-disable-next-line prefer-const
          let total = progressEvent?.srcElement?.getResponseHeader('content-length') || 0
          this.progressValue = (progressEvent.loaded / total) * 100
        },
      })
        .then(res => {
          const { data } = res.data
          const a = document.createElement('a')
          a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
          if (this.getBulkResponse()) {
            a.download = `Tiket_Bulk_Komship_${moment().format('DD-MM-YYYY_HH-mm')}.xlsx`
          } else {
            a.download = `Tiket_Komship_${moment().format('DD-MM-YYYY_HH-mm')}.xls`
          }
          a.click()
          this.showProgress = false
          this.message = 'Download selesai'
        })
        .catch(() => {
          this.showProgress = false
          this.message = 'Gagal download'
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal download data tiket',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    handleCheckedStatus(data) {
      const findIndexObj = this.dataStatus.findIndex(item => item.value === data.value)
      const findObj = this.ticketStatus.findIndex(item => item === data.value)
      if (this.dataStatus[findIndexObj].onCheck === true) {
        this.ticketStatus.push(data.value)
      } else {
        this.ticketStatus.splice(findObj, 1)
      }
    },
    handleCheckedPrioritas(data) {
      const findIndexObj = this.prioritasList.findIndex(item => item.value === data.value)
      const findObj = this.ticketPrioritas.findIndex(item => item === data.value)
      if (this.prioritasList[findIndexObj].onCheck === true) {
        this.ticketPrioritas.push(data.value)
      } else {
        this.ticketPrioritas.splice(findObj, 1)
      }
    },
    closeModal() {
      this.defaultFilter()
      this.message = ''
      this.$bvModal.hide('modal-download-ticket')
    },
    getBulkResponse() {
      if (this.$route.name === 'bulk-response') {
        return true
      }
      return false
    },
    isBulkResponse(value) {
      if (this.getBulkResponse()) {
        if (value === 0 || value === 3) {
          return true
        }
        return false
      }
      return false
    },
  },
}
</script>
